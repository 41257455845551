import React, { useEffect } from "react";
import DottedMap from "dotted-map/without-countries";
import mapJson from "./map.json";
import axios from "axios";

function getRandomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

function Map() {
  const BASE_URL = "https://api.coden.io";

  const map = new DottedMap({ map: mapJson });

  async function getMapPins() {
    try {
      const response = await axios.get(BASE_URL + "/stats/map/location");
      return response.data.data;
    } catch (err) {
      console.log(err);
    }
  }

  const fetchData = async () => {
    try {
      const pins = await getMapPins();
      console.log(pins);
      pins.map((pin) =>
        map.addPin({
          lat: Number(pin.lat),
          lng: Number(pin.lon),
          svgOptions: { color: "#ffe84c", radius: 0.8 },
        })
      );
    } catch (error) {
      console.error("Error fetching map pins:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const svgMap = map.getSVG({
    radius: 0.21,
    color: "#949385",
    shape: "circle",
    backgroundColor: "transparent",
  });

  return (
    <div>
      <img src={`data:image/svg+xml;utf8,${encodeURIComponent(svgMap)}`} />
    </div>
  );
}

export default Map;
