import React from "react";

import Bars3Icon from "@heroicons/react/24/solid/Bars3Icon";
import { Box, IconButton, Stack, SvgIcon, useMediaQuery } from "@mui/material";

import { ConnectWallet } from "@thirdweb-dev/react";

const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 70;

export const TopNav = (props) => {
  const { onNavOpen } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  return (
    <>
      <Box
        component="header"
        sx={{
          backdropFilter: "blur(8px)",
          backgroundColor: "#151517ad",
          position: "sticky",
          // width: "100%",
          left: {
            lg: `${SIDE_NAV_WIDTH}px`,
          },
          top: 0,
          width: {
            lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
          },
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2,
          }}
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            {!lgUp && (
              <IconButton onClick={onNavOpen}>
                <SvgIcon fontSize="small">
                  <Bars3Icon />
                </SvgIcon>
              </IconButton>
            )}
          </Stack>
          <Stack alignItems="center" direction="row" spacing={2}>
            <IconButton>
              <ConnectWallet
                style={{ background: "#ffe84c", color: "black" }}
              />
            </IconButton>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
