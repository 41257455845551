import React, { useState, useEffect } from "react";
import { Typography, Grid } from "@mui/material";
import StatsCard from "./Common/StatsCard";
import Chart from "react-apexcharts";
import axios from "axios";
import Map from "./Map";

function Home() {
  const BASE_URL = "https://api.coden.io";
  const [stats, setStats] = useState(null);
  const [perHourStats, setPerHourStats] = useState(null);

  const pieData = {
    options: {
      labels: [".js", ".css", "images", "Others"],
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.globals.labels[opts.seriesIndex];
        },
        style: {
          colors: ["#ffe84c"],
          fontWeight: "400",
        },
      },
      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "1%",
          },
        },
      },
      stroke: {
        colors: ["#7c7f81", "#7c7f81"], // Fix border color
        width: 2, // Adjust border width if needed
      },
      colors: ["transparent"],
    },
    series: [4, 5, 8, 14],
  };

  const barData = {
    options: {
      chart: {
        background: "transparent",
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        labels: {
          show: true,
          style: {
            colors: [
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
            ],
          },
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            colors: [
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
            ],
          },
        },
        axisTicks: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "30px",
          borderRadius: 0,
          colors: {
            ranges: [
              {
                from: 0,
                to: 10000000000000,
                color: "#ffe84c",
              },
            ],
          },
        },
      },
      grid: {
        show: true,
        borderColor: "#7c7f81",
        strokeDashArray: 4,
      },
      legend: {
        show: false,
      },
      stroke: {
        curve: "smooth",
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    series: [
      {
        name: "series-2",
        data: [30, 40, 45, 50, 49, 60, 70, 102],
      },
    ],
  };

  const lineData = {
    options: {
      chart: {
        background: "transparent",
        // stacked: false,
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        labels: {
          show: true,
          style: {
            colors: [
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
            ],
          },
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            colors: [
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
              "#7c7f81",
            ],
          },
        },
        axisTicks: {
          show: false,
        },
      },
      plotOptions: {
        line: {
          // columnWidth: "3px",
          // borderRadius: 0,
        },
      },
      colors: ["#ffe84c", "#ffe84c"],
      grid: {
        show: true,
        borderColor: "#7c7f81",
        strokeDashArray: 4,
      },
      legend: {
        show: false,
      },
      stroke: {
        curve: "smooth",
      },
      tooltip: {
        enabled: false,
      },
    },
    series: [
      {
        name: "series-1",
        data: perHourStats ? perHourStats : [],
      },
    ],
  };

  async function fetchStatsData() {
    try {
      const response = await axios.get(`${BASE_URL}/stats`);
      setStats(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function fetchStatsPerHourData() {
    try {
      const response = await axios.get(`${BASE_URL}/stats/statsPerHour`);
      console.log(response.data.data);
      const val = response?.data?.data.map((point) => point.count);
      setPerHourStats(val);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchStatsData();
    fetchStatsPerHourData();
  }, []);

  return (
    <div className="bg-img">
      <div className="content">
        {/* <div className="background-text">CODEN</div> */}
        <Grid container spacing={3} sx={{ mt: 6, mb: 3, p: 2 }}>
          <Grid item md={4}>
            <StatsCard
              title="Total Active Users"
              value={stats?.activeUserCount}
            />
          </Grid>
          <Grid item md={4}>
            <StatsCard title="Total Installed" value={stats?.totalUsers} />
          </Grid>
          <Grid item md={4}>
            <StatsCard title="Total Runtime" value={stats?.totalActiveTime} />
          </Grid>
          <Grid item md={4}>
            <StatsCard title="Total Rewards" value={stats?.totalRewards} />
          </Grid>
          <Grid item md={4}>
            <StatsCard
              title="Total Rewards Claimed"
              value={stats?.totalRewardsClaimed}
            />
          </Grid>
          <Grid item md={4}>
            <StatsCard title="Total Bandwidth" value={`20MB`} />
          </Grid>
        </Grid>
        <Grid container gap={4} sx={{ mb: 3, p: 2 }}>
          <Grid
            item
            md={5.8}
            sx={{
              background: "#27272442",
              backdropFilter: "blur(6px)",
              color: "rgb(255, 255, 255)",
              // ml: 2,
              borderRadius: "16px",
              width: "100%",
              boxShadow: "#ffe84ccf 0px 0px 6px 2px",
            }}
          >
            <Typography variant="h5" component="h2" sx={{ pt: 4, ml: 2 }}>
              Active Users Per Hour
            </Typography>
            <div>
              <Chart
                options={lineData.options}
                series={lineData.series}
                type="line"
              />
            </div>
          </Grid>

          <Grid
            item
            md={5.8}
            sx={{
              background: "#27272442",
              backdropFilter: "blur(6px)",
              color: "rgb(255, 255, 255)",
              // ml: 2,
              borderRadius: "16px",
              width: "100%",
              boxShadow: "#ffe84ccf 0px 0px 6px 2px",
            }}
          >
            <Typography variant="h5" component="h2" sx={{ pt: 4, ml: 2 }}>
              Total Rewards Distributed per hour
            </Typography>
            <Chart
              options={barData.options}
              series={barData.series}
              type="bar"
            />
          </Grid>
        </Grid>

        <Grid container gap={4} sx={{ mb: 6, p: 2 }}>
          <Grid
            item
            md={5.8}
            sx={{
              background: "#27272442",
              backdropFilter: "blur(6px)",
              color: "rgb(255, 255, 255)",
              borderRadius: "16px",
              width: "100%",
              boxShadow: "#ffe84ccf 0px 0px 6px 2px",
            }}
          >
            <Typography
              variant="h5"
              component="h2"
              sx={{ pt: 4, pb: 4, ml: 2 }}
            >
              Users Location
            </Typography>
            <Map />
          </Grid>

          <Grid
            item
            md={5.8}
            sx={{
              background: "#27272442",
              backdropFilter: "blur(6px)",
              color: "rgb(255, 255, 255)",
              boxShadow: "#ffe84ccf 0px 0px 6px 2px",
              borderRadius: "16px",
              width: "100%",
            }}
          >
            <Typography
              variant="h5"
              component="h2"
              sx={{ pt: 4, pb: 4, ml: 2 }}
            >
              File Cached
            </Typography>
            <Chart
              options={pieData.options}
              series={pieData.series}
              type="donut"
              height={300}
            />
          </Grid>
        </Grid>
      </div>
      {/* <div
        style={{
          position: "absolute",
          bottom: "-5rem",
          right: "12rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          fontWeight: 900,
          zIndex: -1,
        }}
      >
        <p
          style={{
            textAlign: "center",
            fontSize: "14rem",
            background: `linear-gradient(357deg, #ffe84c 20%, #ffffff 58%) text`,
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent",
            opacity: 0.1,
            display: "inline-block",
          }}
        >
          CODEN
        </p>
      </div> */}
    </div>
  );
}

export default Home;
