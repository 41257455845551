import React from "react";
import { Paper, Typography, Stack, Grid, IconButton } from "@mui/material";
// import InfoIcon from "@mui/icons-material/Info";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function DetailsCard({ title, value }) {
  function copyToClipboard(text) {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          console.log("Text copied to clipboard!");
          alert("Copied to clipboard!");
        })
        .catch((err) => {
          console.error("Could not copy text: ", err);
        });
    }
  }

  return (
    <>
      <Paper
        elevation={2}
        variant="rounded"
        sx={{
          padding: "24px",
          background: "#27272442",
          backdropFilter: "blur(6px)",
          color: "rgb(255, 255, 255)",
          borderRadius: "16px",
          boxShadow: "#ffe84ccf 0px 0px 6px 2px",
        }}
      >
        <Grid container>
          <Grid item md={9}>
            <Typography variant="subtitle1" component="h4">
              {title}
            </Typography>
            {/* <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ marginTop: "16px", marginBottom: "12px" }}
            >
              <InfoIcon sx={{ color: "#2da16f" }} />
            </Stack> */}

            {title === "Referal Code" ? (
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{ marginTop: "16px", marginBottom: "12px" }}
              >
                <Typography variant="h3" sx={{ mt: 2 }}>
                  {value}
                </Typography>

                <IconButton onClick={(e) => copyToClipboard(value)}>
                  <ContentCopyIcon
                    sx={{ color: "#2da16f", cursor: "pointer" }}
                  />
                </IconButton>
              </Stack>
            ) : (
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{ marginTop: "16px", marginBottom: "12px" }}
              >
                <Typography variant="h3" sx={{ mt: 2 }}>
                  {value}
                </Typography>
              </Stack>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default DetailsCard;
