import React from "react";
import { Layout } from "./components/navbar/layout";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./components/Home";
import Rewards from "./components/Rewards";
import Profile from "./components/Profile";
import Leaderboard from "./components/Leaderboard";

function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
        </Routes>
        <Routes>
          <Route
            exact
            path="/rewards"
            element={
              <Layout>
                <Rewards />
              </Layout>
            }
          />
        </Routes>
        <Routes>
          <Route
            exact
            path="/user"
            element={
              <Layout>
                <Profile />
              </Layout>
            }
          />
        </Routes>
        <Routes>
          <Route
            exact
            path="/leaderboard"
            element={
              <Layout>
                <Leaderboard />
              </Layout>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
