import React from "react";
import { Paper, Typography, Stack, Grid } from "@mui/material";
import Chart from "react-apexcharts";

function StatsCard({ title, value }) {
  function generateRandomValue(lowerLimit, upperLimit, round = false) {
    const random = Math.random();

    const scaledRandom = random * (upperLimit - lowerLimit) + lowerLimit;
    const randomValue = round
      ? Math.round(scaledRandom)
      : scaledRandom.toFixed(2);

    return randomValue;
  }

  const data = {
    options: {
      chart: {
        background: "transparent",
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "3px",
          borderRadius: 0,
        },
      },
      colors: ["#ffe84c", "#ffe84c"],
      grid: {
        show: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
    },

    series: [
      {
        name: "series-1",
        data: [
          generateRandomValue(10, 50, true),
          generateRandomValue(10, 20, true),
          generateRandomValue(50, 60, true),
          generateRandomValue(10, 55, true),
          generateRandomValue(70, 90, true),
          generateRandomValue(50, 80, true),
          generateRandomValue(80, 100, true),
          generateRandomValue(20, 50, true),
        ],
      },
    ],
  };
  return (
    <Paper
      elevation={2}
      variant="rounded"
      sx={{
        padding: "24px",
        background: "#27272442",
        backdropFilter: "blur(6px)",
        color: "rgb(255, 255, 255)",
        borderRadius: "16px",
        boxShadow: "#ffe84ccf 0px 0px 6px 2px",
      }}
    >
      <Grid container>
        <Grid item md={9}>
          <Typography variant="subtitle1" component="h4">
            {title}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ marginTop: "16px", marginBottom: "12px" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
              style={{ height: "24px", width: "24px", color: "#2fbe7f" }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941"
              />
            </svg>

            <Typography variant="subtitle2">
              {generateRandomValue(1, 5, false)}
            </Typography>
          </Stack>
          <Typography variant="h3">{value}</Typography>
        </Grid>
        <Grid item md={3}>
          <div>
            <Chart
              options={data.options}
              series={data.series}
              type="bar"
              height={120}
              width="100%"
            />
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default StatsCard;
