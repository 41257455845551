import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import { TableVirtuoso, TableComponents } from "react-virtuoso";
import axios from "axios";

const columns = [
  {
    width: 70,
    label: "Rank",
    dataKey: "rank",
  },
  {
    width: 180,
    label: "Wallet Address",
    dataKey: "walletAddress",
  },
  {
    width: 100,
    label: "Referal Reward",
    dataKey: "referralRewards",
  },
  {
    width: 50,
    label: "Users Referred",
    dataKey: "usersReferred",
    numeric: true,
  },
];

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead: React.forwardRef((props, ref) => (
    <TableHead {...props} ref={ref} />
  )),
  TableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          style={{ width: column.width }}
          sx={{
            backgroundColor: "#ffe84c !important",
            p: 3,
            fontSize: "14px !important",
          }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

function rowContent(_index, row) {
  return (
    <React.Fragment>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          sx={{ color: "white", borderBottomColor: "#3a3a3a", py: 3 }}
        >
          {row[column.dataKey]}
        </TableCell>
      ))}
    </React.Fragment>
  );
}

function Leaderboard() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  const BASE_URL = "https://api.coden.io";

  async function fetchLeaderboard() {
    try {
      const response = await axios.get(BASE_URL + "/leaderboard");
      console.log(response.data.data);
      const leaderboardData = response.data.data.map((user, index) => ({
        rank: index + 1,
        walletAddress: user.walletAddress,
        referralRewards: user.referralRewards,
        usersReferred: user.referredUsers.length,
      }));
      setRows(leaderboardData);
    } catch (error) {
      console.error("Error fetching leaderboard data:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchLeaderboard();
  }, []);

  return (
    <>
      <div
        className="bg-img"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "92vh",
        }}
      >
        <div
          className="content"
          style={{
            padding: "40px",
            overflow: "hidden",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading ? (
            <CircularProgress style={{ color: "#fff" }} />
          ) : (
            rows &&
            rows.length > 0 && (
              <Paper style={{ height: "85vh", width: "100%" }}>
                <TableVirtuoso
                  data={rows}
                  components={VirtuosoTableComponents}
                  fixedHeaderContent={fixedHeaderContent}
                  itemContent={rowContent}
                  style={{ background: "#151516" }}
                />
              </Paper>
            )
          )}
        </div>
      </div>
    </>
  );
}

export default Leaderboard;
